import { useEffect, useState } from 'react';
import styles from './Select.module.scss';
import Common from '@/src/modules/Common';
import Icon from '@/components/icon/Icon';
import useOutsideClick from '@/src/hooks/useOutsideClick';
import keyEnterCheck from '@/src/helpers/MiscHelper';

const ADDITIONAL_TEXT_FIELD = 'title';
const ACTION_FIELD = 'action';

export default function Select({
    inputRef,
    items = [],
    value,
    onValueChanged,
    valueField = 'value',
    textField = 'text',
    className,
    inputClassName,
    onActionClick,
}) {
    const [isOpen, setIsOpen] = useState(false);
    // noinspection EqualityComparisonWithCoercionJS
    const [activeIndex, setActiveIndex] = useState(
        value !== undefined ? items.findIndex((item) => item[valueField] == value) : null
    );

    useEffect(() => {
        // noinspection EqualityComparisonWithCoercionJS
        setActiveIndex(value !== undefined ? items.findIndex((item) => item[valueField] == value) : null);
    }, [items, value, valueField]);

    const selectActiveIndex = (index) => {
        if (inputRef) {
            inputRef.current.value = items[index][valueField];
        }
        setActiveIndex(index);
        if (typeof onValueChanged === 'function') {
            onValueChanged(items[index][valueField]);
        }
    };

    const onKeyDown = (e) => {
        if (Common.isPressEscape(e) || Common.isPressEnter(e)) {
            setIsOpen(false);
        } else if (Common.isPressKeyDown(e)) {
            if (activeIndex < items.length - 1) {
                selectActiveIndex(activeIndex + 1);
            }
        } else if (Common.isPressKeyUp(e)) {
            if (activeIndex > 0) {
                selectActiveIndex(activeIndex - 1);
            }
        }
    };

    const updateActiveIndex = (event) => {
        const value = event.target.value;
        // noinspection EqualityComparisonWithCoercionJS
        let index = items.findIndex((item) => item[valueField] == value);
        if (index !== -1) {
            selectActiveIndex(index);
        } else if (inputRef) {
            inputRef.current.value = items[activeIndex][valueField];
            if (typeof onValueChanged === 'function') {
                onValueChanged(value);
            }
        }
    };

    const handleClickOutside = () => {
        setIsOpen(false);
    };

    const onItemClick = (item, index) => {
        setIsOpen(false);
        if (item[ACTION_FIELD] && typeof onActionClick === 'function') {
            onActionClick(item[ACTION_FIELD]);
        } else {
            selectActiveIndex(index);
        }
    };

    const ref = useOutsideClick(handleClickOutside);

    return (
        <div
            className={styles['select'] + ' ' + (inputClassName || '') + (isOpen ? ' ' + 'select--open' : '')}
            onKeyDown={onKeyDown}
            ref={ref}
        >
            <select
                className={'select__native ' + (className || '')}
                ref={inputRef}
                value={value}
                onChange={updateActiveIndex}
                aria-readonly={true}
            >
                {items?.map((item, index) => (
                    <option
                        key={`option-${index}` /* NOSONAR */}
                        value={item[valueField] ?? item[textField] ?? item[ADDITIONAL_TEXT_FIELD]}
                    >
                        {item[textField] || item[ADDITIONAL_TEXT_FIELD]}
                    </option>
                ))}
            </select>

            <div className="select__wrapper">
                <div className={'select__custom'} tabIndex="0" onClick={() => setIsOpen(!isOpen)} onKeyDown={onKeyDown}>
                    <span className={'select__custom-text'}>
                        <span className="select__custom-text-inner">
                            {items?.[activeIndex] &&
                                (items[activeIndex][textField] || items[activeIndex][ADDITIONAL_TEXT_FIELD])}
                        </span>
                        <Icon id="arrow-down" modifier={'select__custom-icon'} />
                    </span>
                </div>
                <div className={'select__options-wrapper h-customized--scrollbar-gray h-customized--scrollbar-thin'}>
                    <div className={'select__options-inner'}>
                        {items &&
                            isOpen &&
                            items.map((item, index) => (
                                <div
                                    key={`select-${index}` /* NOSONAR */}
                                    tabIndex={0}
                                    onClick={() => {
                                        onItemClick(item, index);
                                    }}
                                    onKeyDown={keyEnterCheck(onItemClick, item, index)}
                                    className={
                                        'select__option' +
                                        (activeIndex === index ? ' ' + 'select__option--selected' : '')
                                    }
                                >
                                    {item[textField] || item[ADDITIONAL_TEXT_FIELD] || item[valueField]}
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
