import styles from './Tabs.module.scss';
import Select from '@/components/select/Select';
import { useCallback, useEffect, useRef, useState } from 'react';
import Icon from '@/components/icon/Icon';
import { getTimeDay } from '@/src/helpers/FormatHelper';
import Common, { getTimeMilliseconds, throttle } from '@/src/modules/Common';
import { MILLISECONDS_IN_DAY, userDate } from '@/src/helpers/TimeHelper';
import { CATCHUP_AVAILABLE_DAYS } from '@/src/constants/global';

const getRelativeClientRect = (elem, parentElem) => {
    let rect = elem.getBoundingClientRect();
    let parentRect = parentElem.getBoundingClientRect();

    return {
        bottom: parentRect.bottom - rect.bottom,
        height: rect.height,
        left: rect.left - parentRect.left,
        right: parentRect.right - rect.right,
        top: rect.top - parentRect.top,
        width: rect.width,
    };
};

export default function Tabs({
    header,
    modifier,
    tabs = [],
    onNext,
    tabIndex = 0,
    onChangeActiveIndex,
    children,
    needControlsContainer = true,
}) {
    const [activeTabIndex, setActiveTabIndex] = useState(ACTIVE_TAB_INDEX);
    const tabsRef = useRef();
    const [fadeClass, setFadeClass] = useState('');

    const onChangeTab = onChangeActiveIndex ?? setActiveTabIndex;

    useEffect(() => {
        setActiveTabIndex(tabIndex);
    }, [tabIndex]);

    return (
        <div className={styles['tabs'] + ' ' + (modifier || '') + fadeClass} ref={tabsRef}>
            {header ? <div className="tabs__header">{header}</div> : ''}

            <TabsControls
                {...{
                    activeTabIndex,
                    setActiveTabIndex: onChangeTab,
                    tabs,
                    tabsRef,
                    onNext,
                    setFadeClass,
                    needContainer: needControlsContainer,
                }}
            />

            <div className="tabs__select">
                <Select
                    inputClassName={'select--tabs'}
                    value={activeTabIndex}
                    items={tabs}
                    textField={'selectTitle'}
                    valueField={'id'}
                    onValueChanged={onChangeTab}
                />
            </div>

            <ul className={styles['tabs__container']}>
                {children ||
                    tabs.map((item, index) => (
                        <TabData key={index /* NOSONAR */} isActive={index === activeTabIndex}>
                            {item.content}
                        </TabData>
                    ))}
            </ul>
        </div>
    );
}

function TabsControls({
    activeTabIndex,
    setActiveTabIndex,
    tabs,
    tabsRef,
    onNext,
    setFadeClass,
    needContainer = true,
}) {
    const [shiftLeft, setShiftLeft] = useState(0);
    const [shiftRight, setShiftRight] = useState(0);
    const shiftTabsFilterRef = useRef(0);
    const filterItemsRef = useRef([]);
    const tabsFilterWrapperRef = useRef();
    const tabsFilterRef = useRef();

    const onNextClick = () => {
        const tabsFilterWrapper = /** @type {HTMLElement} */ tabsFilterWrapperRef.current;
        const tabsFilter = /** @type {HTMLElement} */ tabsFilterRef.current;
        const filterItems = /** @type {HTMLElement[]} */ filterItemsRef.current;
        let index = 0;
        for (const filterItem of filterItems) {
            let itemShift = Math.floor(getRelativeClientRect(filterItem, tabsFilterWrapper).right);
            if (itemShift < 0) {
                shiftTabsFilterRef.current += itemShift;
                tabsFilter.style.marginLeft = `${shiftTabsFilterRef.current}px`;
                if (typeof onNext === 'function') {
                    onNext(index);
                }
                break;
            }
            index++;
        }
        setTimeout(controlVisibilityArrows, 200);
    };

    const onPreviousClick = () => {
        const tabsFilterWrapper = /** @type {HTMLElement} */ tabsFilterWrapperRef.current;
        const tabsFilter = /** @type {HTMLElement} */ tabsFilterRef.current;
        const filterItems = /** @type {HTMLElement[]} */ filterItemsRef.current;
        for (let i = filterItems.length - 1; i >= 0; i--) {
            const filterItem = filterItems[i];
            let itemShift = Math.ceil(getRelativeClientRect(filterItem, tabsFilterWrapper).left);
            if (itemShift < 0) {
                shiftTabsFilterRef.current -= itemShift;
                tabsFilter.style.marginLeft = `${shiftTabsFilterRef.current}px`;
                break;
            }
        }
        setTimeout(controlVisibilityArrows, 200);
    };

    const calculateWidthElemsTabFilter = useCallback(() => {
        const tabsFilter = /** @type {HTMLElement} */ tabsFilterRef.current;
        const filterItems = /** @type {HTMLElement[]} */ filterItemsRef.current;
        let widthFilter = 0;
        for (const filterItem of filterItems) {
            widthFilter += getRelativeClientRect(filterItem, tabsFilter).width; // Math.ceil?
        }
        tabsFilter.style.width = `${widthFilter}px`;
    }, []);

    const controlVisibilityArrows = useCallback(() => {
        const tabsFilter = /** @type {HTMLElement} */ tabsFilterRef.current;
        const tabsFilterWrapper = /** @type {HTMLElement} */ tabsFilterWrapperRef.current;
        let shiftRight = Math.ceil(getRelativeClientRect(tabsFilter, tabsFilterWrapper).right);
        let shiftLeft = Math.ceil(getRelativeClientRect(tabsFilter, tabsFilterWrapper).left);
        setShiftRight(shiftRight);
        setShiftLeft(shiftLeft);
        let fadeClass = '';
        if (shiftLeft < 0) {
            fadeClass += ' tabs--left-fade';
        }
        if (shiftRight < 0) {
            fadeClass += ' tabs--right-fade';
        }
        setFadeClass(fadeClass);
    }, [setFadeClass]);

    const checkWidth = useCallback(() => {
        const tabs = /** @type {HTMLElement} */ tabsRef.current;
        filterItemsRef.current = [...tabs.querySelectorAll(`.${styles['tabs__filter-item']}`)];
        calculateWidthElemsTabFilter();
        controlVisibilityArrows();
    }, [controlVisibilityArrows, calculateWidthElemsTabFilter]);

    useEffect(() => {
        const calculate = throttle(checkWidth);
        calculate();
        window.addEventListener('resize', calculate);
        return () => {
            window.removeEventListener('resize', calculate);
        };
    }, [tabs, checkWidth]);

    useEffect(() => {
        if (!needContainer) {
            return;
        }
        const calculate = throttle(() => setTimeout(checkWidth));
        window.addEventListener('scroll', calculate);
        return () => {
            window.removeEventListener('scroll', calculate);
        };
    }, [needContainer, checkWidth]);

    const onChangeTab = (event) => {
        let target = /** @type {HTMLElement} */ event.target;
        if (target.dataset['index'] === undefined) {
            target = target.parentElement;
        }
        const index = target.dataset['index'];
        if (index !== undefined) {
            setActiveTabIndex(Number(index));
        }
    };

    return (
        <div className="tabs__controls" style={{ marginRight: '0px' }}>
            <Wrapper divClass={needContainer ? 'container tabs__controls-container' : ''}>
                {needContainer && <div className="tabs__fake-select"> </div>}
                <div className="tabs__filter-wrapper" ref={tabsFilterWrapperRef}>
                    <button
                        className={
                            'tabs__filter-arrow tabs__filter-arrow--next ' +
                            (shiftRight < 0 ? ' tabs__filter-arrow--visible' : '')
                        }
                        type="button"
                        title="Следующий элемент"
                        onClick={onNextClick}
                    >
                        <Icon id="arrow-right" />
                        <span className="tabs__filter-arrow-text">Следующий</span>
                    </button>
                    <button
                        className={
                            'tabs__filter-arrow tabs__filter-arrow--prev ' +
                            (shiftLeft < 0 ? ' tabs__filter-arrow--visible' : '')
                        }
                        type="button"
                        title="Предыдущий элемент"
                        onClick={onPreviousClick}
                    >
                        <Icon id="arrow-left" />
                        <span className="tabs__filter-arrow-text">Предыдущий</span>
                    </button>
                    <ul
                        className="tabs__filter"
                        role="tablist"
                        ref={tabsFilterRef}
                        onClick={onChangeTab}
                        onKeyDown={onChangeTab}
                    >
                        {tabs.map((item, index) => (
                            <TabFilterItem
                                key={index} // NOSONAR
                                index={index}
                                title={item.title}
                                subtitle={item.subtitle}
                                isActive={index === activeTabIndex}
                            />
                        ))}
                    </ul>
                </div>
            </Wrapper>
        </div>
    );
}

function TabFilterItem({ title, subtitle, isActive = false, index }) {
    return (
        <li className={styles['tabs__filter-item']} role="presentation">
            <button
                className={
                    styles['tabs__filter-button'] + (isActive ? ' ' + styles['tabs__filter-button--active'] : '')
                }
                type="button"
                role="tab"
                data-index={index}
            >
                <h2 className={styles['tabs__filter-title']} title={title}>
                    {title}
                </h2>
                {subtitle && <span className={styles['tabs__filter-subtitle']}>{subtitle}</span>}
            </button>
        </li>
    );
}

export function TabData({ children, isActive = false }) {
    return (
        <li className={'tabs__container-item' + (isActive ? ' tabs__container-item--active' : ' hidden')}>
            {children}
        </li>
    );
}

// numbers of days of the week (N) where schedule ends in sunday
const DAYS_WITH_SCHEDULE_END_IN_SUNDAY = [1, 2, 3];
// number of next days if current day not in DAYS_WITH_SCHEDULE_END_IN_SUNDAY
const COUNT_OF_NEXT_DAYS = 7;
// how much previous days to show
export const COUNT_OF_PREVIOUS_DAYS_TO_SHOW = CATCHUP_AVAILABLE_DAYS;
// active tab on page load
export const ACTIVE_TAB_INDEX = COUNT_OF_PREVIOUS_DAYS_TO_SHOW;

export function getScheduleTabs() {
    let date = userDate(Common.getTime());
    let currentDayNum = date.getDay();
    let count = COUNT_OF_PREVIOUS_DAYS_TO_SHOW + COUNT_OF_NEXT_DAYS;
    if (DAYS_WITH_SCHEDULE_END_IN_SUNDAY.includes(currentDayNum)) {
        count -= currentDayNum;
    }
    let startDay = new Date(date.setTime(date.getTime() - COUNT_OF_PREVIOUS_DAYS_TO_SHOW * MILLISECONDS_IN_DAY));

    let tabs = [];
    for (let i = 0; i <= count; i++) {
        let title = getTimeDay(startDay);
        let dayShift = Math.round((startDay.getTime() - getTimeMilliseconds()) / MILLISECONDS_IN_DAY);
        tabs.push({ id: i, name: title, title, dayShift });
        startDay.setTime(startDay.getTime() + MILLISECONDS_IN_DAY);
    }
    return tabs;
}

function Wrapper({ children, divClass }) {
    return divClass ? <div className={divClass}>{children}</div> : children;
}
