import { useEffect, useRef } from 'react';

export default function useOutsideClick(callback) {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            const element = /** @type {HTMLElement} */ ref.current;
            if (element && !element.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return ref;
}
